main.main {
  --background-body: #292929;
  --text-color: #E5E5E5;
  --primary-color: #4d7aff;
  /* --second-color: #89DDFF; */
}

header.header {
  position: fixed;
  width: 100%;
  height: 3.5rem;
  top: 0px;
  left: 0px;
  background-color: var(--background-content);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.header__content {
  max-width: 1280px;
  padding: 0 1rem;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 3.5rem;
  background-color: var(--background-content);
}

.logo {
  font-size: 20px;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  color: var(--text-color);
}

main.main {
  width: 70%;
  margin: auto;
  margin-bottom: 3rem;
  margin-top: 7rem;
  background-color: var(--background-content);
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 3px;
}

blockquote {
  background: rgba(77, 255, 124, 0.2);
  border-left: 10px solid var(--primary-color);
  margin: 1.5em 10px;
  padding: 1em 10px .1em 10px;
  quotes: "\201C""\201D""\2018""\2019";
}

a {
  color: var(--primary-color);
  font-weight: 500;
}

code {
  color: var(--second-color);
  background-color: rgb(129 139 152 / 12%);
}

.dark-mode-icon {
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  main.main {
    width: 70%;
  }
}

@media screen and (max-width: 920px) {
  main.main {
    width: 80%;
  }
}

@media screen and (max-width: 520px) {
  main.main {
    width: 90%;
  }
}

.article-wrapper {
  padding: 2rem;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
}

.article__cover img {
  width: 100%;
}

/* Basic table styling */
table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    font-size: 16px;
    text-align: left;
}

/* Header styling */
th {
    background-color: #f2f2f2;
    color: #333;
    font-weight: bold;
    padding: 12px 15px;
    border: 1px solid #ddd;
}

/* Table cell styling */
td {
    background-color: #f9f9f9;
    padding: 12px 15px;
    border: 1px solid #ddd;
}

/* Hover effect for rows */
tr:hover {
    background-color: #f1f1f1;
}

/* Stylize code-like text */
/* code {
    background-color: #eaeaea;
    color: #555;
    padding: 2px 4px;
    border-radius: 4px;
    font-family: monospace;
} */

/* Link styling */
a {
    color: #007bff;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}
